import React from "react";
import { Card, CardBody, Col } from "reactstrap";

function CardMaintenance(props) {
  return (
    <>
      <Col md="4">
        <Card className="mt-4">
          <CardBody>
            {props.children}
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default CardMaintenance;
