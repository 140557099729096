import React from "react";
import { Container, Row, Col } from "reactstrap";
import maintenance from "./assets/main3.svg";
import CardMaintenance from "./card";

function Home() {
  return (
    <div>
      <section className="pt-sm-5">
        <Container>
          <Row>
            <Col xs="12" className="text-center">
              <div>
                <Row className="justify-content-center">
                  <Col>
                    <div>
                      <img
                        src={maintenance}
                        className="img-fluid mx-auto d-block"
                        width={900}
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <h3 className="mt-5">Site is Under Maintenance</h3>
          <h6>Please check back in sometime.</h6>
          <Row className="mb-3">
            <CardMaintenance>
                <i className="bi bi-broadcast-pin mb-4 h1 text-primary"></i>
                <h5 className="font-size-15 text-uppercase">Why is the Site Down?</h5>
                <p className="text-muted mb-0">We are working on rebulding SwatTechLabs. Our services will continue soon.</p>
            </CardMaintenance>
            <CardMaintenance>
                <i className="bi bi-clock mb-4 h1 text-primary"></i>
                <h5 className="font-size-15 text-uppercase">Website downtime?</h5>
                <p className="text-muted mb-0">We are working on fixing technical issues. website will be available shortly.</p>
            </CardMaintenance>
            <CardMaintenance>
                <i className="bi bi-envelope mb-4 h1 text-primary"></i>
                <h5 className="font-size-15 text-uppercase">Do you need support?</h5>
                <p className="text-muted mb-0">We will be happy to help you, contact us at <a href="mailto:contact@swattechlabs.com">contact@swattechlabs.com</a>.</p>
            </CardMaintenance>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Home;
