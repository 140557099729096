import logo from "./logo1.png";
import logo_name from "./logo_name.png";
import "./App.css";
import Home from "./home";

function App() {
  return (
    <div className="text-center mt-12">
      <img src={logo_name} className="mt-10" alt="" />
      <img src={logo} className="App-logo mt-10" alt="logo" />
      {Home()}
    </div>
  );
}

export default App;
